<template>
  <section class="user-guide-fifth-step">
    <div class="user-guide__input-wrapper">
      <input
        class="user-guide__input"
        id="product-price"
        type="text"
        :placeholder="$translate('user_guide.step_5.collection_name')"
        :value="data.collection_title"
        @input="$emit('update_value', $event.target.value, 'collection_title')"
      />
    </div>
    <p class="user-guide-fifth-step__image-title">
      {{ $translate("user_guide.step_5.collection_image") }}
    </p>
    <image_upload
      :images="data.collection_image ? [data.collection_image] : []"
      :adding_product_images="false"
      :title="data.collection_title || 'collection_image'"
      @updating="updated_image => $emit('update_value', updated_image, 'collection_image')"
    />
  </section>
</template>

<script>
import image_upload from "./image_upload"

export default {
  components: {
    image_upload,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .user-guide-fifth-step {
    text-align: center;

    &__image-title {
      margin: $half-default-size 0 $double-default-size;
      font-size: $font-size--very-large;
      font-weight: bold;
      text-align: center;
      color: $pure-black;

      @media (max-width: $mobile--large) {
        font-size: $font-size--large;
      }
    }

    .user-guide__input {
      margin-bottom: $double-default-size;
    }
  }
</style>
